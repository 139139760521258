import { DocumentApi } from "@/api";
import i18n from "@/i18n";
import { showErrorSnackbar, showSuccessSnackbar } from "@/utilities/snackbar";

export async function get(enterpriseId, type, params) {
  const { data } = await DocumentApi.get(enterpriseId, type, params);
  return data;
}

export async function save(formData) {
  const response = await DocumentApi.save(formData);
  showSuccessSnackbar(
    200,
    `${formData.get("name")}.${formData
      .get("file")
      .name.split(".")
      .pop()} ${i18n.t("append-to-filename-saved-correctly")}`
  );
  return response.data.document;
}

export async function remove(document) {
  try {
    await DocumentApi.remove(document);
  } catch (ex) {
    showErrorSnackbar(undefined, i18n.t("something-went-wrong") + ex);
  }
  showSuccessSnackbar(
    200,
    `${document.name + document.fileExtension} ${i18n.t(
      "append-to-filename-deleted-correctly"
    )}`
  );
}

export async function download(id) {
  let data;
  try {
    ({ data: data } = await DocumentApi.download(id));
  } catch (ex) {
    showErrorSnackbar(undefined, i18n.t("something-went-wrong") + ex);
  }
  return data;
}
