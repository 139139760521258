import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import { create, all } from "mathjs"; // Importa Math.js
import * as Sentry from "@sentry/vue";

const math = create(all);

Vue.prototype.$math = math;

Vue.config.productionTip = false;

const dsn = process.env.VUE_APP_SENTRY_DSN ?? null;
if (dsn) {
  Sentry.init({
    Vue,
    dsn: dsn,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
