<template>
  <v-navigation-drawer
    app
    permanent
    :dark="isImpersonateEnterprise"
    :color="navColor"
  >
    <template #prepend>
      <div class="d-flex justify-center ma-2 pa-1">
        <DbpLogoNoPayoff :height="40" :width="129" :colors="logoColors" />
      </div>
    </template>
    <template v-if="isImpersonateEnterprise">
      <v-list-item class="px-2">
        <v-list-item-content>
          <div class="d-flex align-center rounded pa-2 techBlue">
            <div class="text-caption white--text font-weight-bold">
              {{ enterpriseName }}
            </div>
            <v-spacer />
            <ExitEnterpriseButtonDialog @confirm="handleClose" />
          </div>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list dense nav>
      <template v-for="route in routes">
        <template v-if="route.subFolders">
          <v-list-group
            :key="'route ' + route.name"
            v-model="route.active"
            :color="listGroupColor"
          >
            <template #activator>
              <v-list-item-title>{{ route.title }}</v-list-item-title>
            </template>
            <template #prependIcon>
              <v-icon>{{ route.icon }}</v-icon>
            </template>
            <v-list-item
              v-for="(subFolder, index) in route.subFolders"
              :key="index"
              :to="{ name: subFolder.name, params: subFolder.params }"
            >
              <v-list-item-title>{{ subFolder.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
        <template v-else>
          <v-list-item
            :key="'route ' + route.name"
            :to="{ name: route.name, params: route.params }"
          >
            <v-list-item-icon>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ route.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </template>
    </v-list>
    <template #append>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              {{ userFullName }}
              <v-spacer></v-spacer>
              <v-btn v-if="!isProduction" icon :to="{ name: 'settings' }">
                <v-icon> mdi-cog </v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EnterpriseService } from "@/services";
import { showErrorSnackbar, showSuccessSnackbar } from "@/utilities/snackbar";
import ExitEnterpriseButtonDialog from "@/components/dialogs/ExitEnterpriseButtonDialog.vue";
import DbpLogoNoPayoff from "../svg/DbpLogoNoPayoff.vue";

export default {
  name: "TheNavbar",
  components: {
    ExitEnterpriseButtonDialog,
    DbpLogoNoPayoff,
  },
  data: () => ({
    enterprise: {},
    allEnterpriseFlat: [],
    referenceFirmId: undefined,
    referenceEnterpriseId: undefined,
  }),
  computed: {
    ...mapGetters({
      userEnterpriseId: "user/getUserEnterpriseId",
      userFirmId: "user/getUserFirmId",
      userRoleId: "user/getUserRoleId",
      userFullName: "user/userFullName",
      getReferenceFirmId: "user/getReferenceFirmId",
      getReferenceEnterpriseId: "user/getReferenceEnterpriseId",
      isImpersonateEnterprise: "user/isImpersonateEnterprise",
      enterpriseHasEsgModule: "user/getUserEnterpriseHasEsgModule",
      firmHasEsgModule: "user/getUserFirmHasEsgModule",
      userRootFirm: "user/getUserRootFirm",
    }),
    logoColors() {
      return this.isImpersonateEnterprise
        ? ["#1a67f8", "#ffffff"]
        : ["#1a67f8", "#050d21"];
    },
    navColor() {
      return this.isImpersonateEnterprise ? "techBlueDark" : "white";
    },
    listGroupColor() {
      return this.isImpersonateEnterprise ? "techPurple" : "techBlue";
    },
    isProduction() {
      return process.env.NODE_ENV === "production";
    },
    enterpriseBaseRoutes() {
      let routes = [
        {
          name: "balances",
          params: {},
          icon: "mdi-scale-balance",
          title: this.$t("balances"),
        },
        {
          name: "business-plans",
          params: {},
          icon: "mdi-chart-box-outline",
          title: this.$t("business-plans"),
        },
        {
          name: "eligibility-tests",
          params: {},
          icon: "mdi-leaf",
          title: this.$t("esg.default"),
        },
        {
          name: "dsr",
          params: {},
          icon: "mdi-book-account",
          title: this.$t("dsr.default"),
        },
        {
          name: undefined,
          params: {},
          icon: "mdi-folder-multiple",
          title: this.$t("documents"),
          subFolders: [
            {
              name: "enterprise-documents",
              params: {},
              icon: "mdi-folder-open",
              title: this.$t("enterprise-documents"),
              disabled: false,
            },
            {
              name: "project-documents",
              params: {},
              icon: "mdi-folder-open",
              title: this.$t("project-documents"),
              disabled: false,
            },
            {
              name: "other-documents",
              params: {},
              icon: "mdi-folder-open",
              title: this.$t("other-documents"),
              disabled: false,
            },
          ],
          disabled: false,
        },
        {
          name: "enterprise-info",
          params: {},
          icon: "mdi-information-box-outline",
          title: this.$t("enterprise-info"),
          disabled: false,
        },
        {
          name: "users",
          params: {},
          icon: "mdi-account-group",
          title: this.$t("users"),
        },
      ];

      if (!this.enterpriseHasEsgModule) {
        let filteredRoutes = routes.filter((item) => {
          return item.name !== "eligibility-tests" && item.name !== "dsr";
        });

        return filteredRoutes;
      }

      return routes;
    },
    firmBaseRoutes() {
      return [
        {
          name: "accountant-users",
          params: {},
          icon: "mdi-account-group",
          title: this.$t("users"),
          disabled: false,
        },
        {
          name: "accountant-enterprises",
          params: {},
          icon: "mdi-domain",
          title: this.$t("enterprises"),
          disabled: false,
        },
      ];
    },
    rootBaseRoutes() {
      return [
        {
          name: "admin-firms",
          params: {},
          icon: "mdi-account-group",
          title: this.$t("firms"),
          disabled: false,
        },
        {
          name: "admin-esg-upload",
          params: {},
          icon: "mdi-leaf",
          title: this.$t("esg.default"),
          disabled: false,
        },
      ];
    },
    firmAdminRoutesComputed() {
      const firmAdminRoutesComputed = this.getReferenceEnterpriseId
        ? [...this.enterpriseBaseRoutes]
        : [...this.firmBaseRoutes];
      return firmAdminRoutesComputed;
    },
    enterpriseAdminRoutesComputed() {
      return [...this.enterpriseBaseRoutes];
    },
    rootBaseRoutesComputed() {
      return [...this.rootBaseRoutes];
    },
    enterpriseName() {
      if (this.selectedEnterprise == null) return "";
      const name = this.selectedEnterprise.name ?? "";
      return name.length > 20 ? name.substring(0, 25) + "..." : name;
    },
    selectedEnterprise() {
      let selectedEnterprise = {};
      if (this.enterprise) {
        selectedEnterprise = this.enterprise;
      } else if (
        this.getReferenceEnterpriseId &&
        this.getReferenceEnterpriseId != null
      ) {
        selectedEnterprise = this.allEnterpriseFlat.find((element) => {
          return element.id === this.getReferenceEnterpriseId;
        });
      }
      return selectedEnterprise;
    },
    selectedFirm() {
      let selectedFirm = {};
      if (this.userRootFirm) {
        selectedFirm = this.userRootFirm;
      }
      return selectedFirm;
    },
    enterpriseHasEsgModuleComputed() {
      if (
        this.selectedEnterprise !== null &&
        this.selectedEnterprise !== undefined &&
        this.selectedEnterprise != {}
      ) {
        return this.selectedEnterprise.hasEsgModule;
      } else {
        return false;
      }
    },
    firmHasEsgModuleComputed() {
      if (
        this.selectedFirm !== null &&
        this.selectedFirm !== undefined &&
        this.selectedFirm != {}
      ) {
        return this.selectedFirm.hasEsgModule;
      } else {
        return false;
      }
    },
    routes() {
      if (this.userFirmId) {
        return this.firmAdminRoutesComputed;
      } else if (this.userEnterpriseId) {
        return this.enterpriseAdminRoutesComputed;
      } else if (this.userFirmId === 0 && this.userRoleId === 0) {
        return this.rootBaseRoutesComputed;
      }
      return [];
    },
  },
  watch: {
    enterpriseHasEsgModuleComputed(newValue) {
      this.setEnterpriseHasEsgModule(newValue);
    },
    firmHasEsgModuleComputed(newValue) {
      this.setFirmHasEsgModule(newValue);
    },
    referenceFirmId(newValue) {
      this.setReferenceFirmId(newValue);
      if (this.referenceFirmId) {
        this.getAllEnterpriseFlat();
      }
    },
    referenceEnterpriseId(newValue) {
      this.setReferenceEnterpriseId(newValue);
      if (newValue) {
        this.getEnterprise(newValue);
      }
      if (this.referenceFirmId) {
        this.getAllEnterpriseFlat();
      }
    },
    getReferenceFirmId(newValue) {
      this.referenceFirmId = newValue;
    },
    getReferenceEnterpriseId(newValue) {
      this.referenceEnterpriseId = newValue;
    },
  },
  mounted() {
    this.referenceFirmId = this.getReferenceFirmId;
    if (
      this.getReferenceEnterpriseId &&
      this.getReferenceEnterpriseId != null
    ) {
      this.referenceEnterpriseId = this.getReferenceEnterpriseId;
    }
  },
  methods: {
    ...mapActions({
      resetState: "user/resetState",
      setReferenceFirmId: "user/setReferenceFirmId",
      setReferenceEnterpriseId: "user/setReferenceEnterpriseId",
      setActiveLocale: "user/setActiveLocale",
      setEnterpriseHasEsgModule: "user/setEnterpriseHasEsgModule",
      setFirmHasEsgModule: "user/setFirmHasEsgModule",
    }),
    logout() {
      try {
        this.resetState();
        this.$router.push({ name: "login" });
        showSuccessSnackbar(200, this.$t("logout-done-correctly"));
      } catch (ex) {
        showErrorSnackbar(undefined, ex);
      }
    },
    async getAllEnterpriseFlat() {
      this.allEnterpriseFlat = await EnterpriseService.getByFirm(
        this.referenceFirmId
      );
    },
    async getEnterprise(id) {
      this.enterprise = await EnterpriseService.get(id);
    },
    handleClose: function () {
      this.setReferenceEnterpriseId(null);
      this.$router.push({ name: "accountant-enterprises" });
      showSuccessSnackbar(200, this.$t("back-to-administration-notice"));
    },
  },
};
</script>
