import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";
import { setI18nLanguage } from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: { name: "login" },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/LoginView.vue"),
    meta: {
      title: "Login",
      header: { show: false },
      navbar: { show: false },
      footer: { show: false },
      container: {
        fillHeight: true,
        fluid: true,
      },
    },
  },
  {
    path: "/admin/login",
    name: "admin-login",
    redirect: { name: "login" },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/DashboardView.vue"),
    meta: {
      title: "dashboard",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
      container: {
        fillHeight: true,
      },
    },
  },
  {
    path: "/registration/confirm",
    name: "confirm-registration",
    component: () =>
      import(
        /* webpackChunkName: "enterprise" */ "@/views/ConfirmRegistration.vue"
      ),
    props: (route) => ({ token: route.query.token }),
    meta: {
      title: "Conferma Registrazione",
      header: { show: false },
      navbar: { show: false },
      footer: { show: false },
      container: {
        fillHeight: true,
        fluid: true,
      },
    },
  },
  {
    path: "/accountant/registration/confirm",
    name: "accountant-confirm-registration",
    component: () =>
      import(
        /* webpackChunkName: "enterprise" */ "@/views/ConfirmRegistration.vue"
      ),
    props: (route) => ({ token: route.query.token }),
    meta: {
      title: "Conferma Registrazione",
      header: { show: false },
      navbar: { show: false },
      footer: { show: false },
      container: {
        fillHeight: true,
        fluid: true,
      },
    },
  },
  {
    path: "/balances",
    name: "balances",
    component: () =>
      import(
        /* webpackChunkName: "balance" */ "@/views/Balances/BalancesView.vue"
      ),
    meta: {
      title: "balances",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/balances/:balanceId(\\d+)",
    name: "balance",
    component: () =>
      import(
        /* webpackChunkName: "balance" */ "@/views/Balances/BalanceView.vue"
      ),
    props: true,
    meta: {
      title: "balance",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/balances/enterprise/:enterpriseId(\\d+)/year/:year(\\d+)/add",
    name: "create-balance",
    component: () =>
      import(
        /* webpackChunkName: "balance" */ "@/views/Balances/CreateBalanceView.vue"
      ),
    props: true,
    meta: {
      title: "create-balance",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/balances/:balanceId(\\d+)/edit",
    name: "edit-balance",
    component: () =>
      import(
        /* webpackChunkName: "balance" */ "@/views/Balances/EditBalanceView.vue"
      ),
    props: true,
    meta: {
      title: "edit-balance",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/business-plans",
    name: "business-plans",
    component: () =>
      import(
        /* webpackChunkName: "business-plans" */ "@/views/BusinessPlans/BusinessPlansView.vue"
      ),
    props: true,
    meta: {
      title: "business-plan-list",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/business-plans/:businessPlanId(\\d+)",
    name: "business-plan",
    component: () =>
      import(
        /* webpackChunkName: "business-plans" */ "@/views/BusinessPlans/BusinessPlanView.vue"
      ),
    props: true,
    meta: {
      title: "business-plan",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: true,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/business-plans/create",
    name: "create-business-plan",
    component: () =>
      import(
        /* webpackChunkName: "business-plans" */ "@/views/BusinessPlans/CreateBusinessPlanView.vue"
      ),
    meta: {
      title: "create-business-plan",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: true,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/business-plans/create/assisted",
    name: "create-business-plan-easy-mode",
    component: () =>
      import(
        /* webpackChunkName: "business-plans" */ "@/views/BusinessPlans/EasyModeBusinessPlanCreate.vue"
      ),
    meta: {
      title: "create-business-plan-easy-mode",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
      store: {
        keepWorkbook: true,
      },
    },
  },
  {
    path: "/business-plans/:businessPlanId(\\d+)/edit",
    name: "edit-business-plan",
    component: () =>
      import(
        /* webpackChunkName: "business-plans" */ "@/views/BusinessPlans/EditBusinessPlanView.vue"
      ),
    props: true,
    meta: {
      title: "edit-business-plan",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: true,
            difference: false,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/business-plans/:businessPlanId(\\d+)/scenario/:scenarioId(\\d+)",
    name: "scenario",
    component: () =>
      import(
        /* webpackChunkName: "scenarios" */ "@/views/Scenarios/ScenarioView.vue"
      ),
    props: true,
    meta: {
      title: "scenario",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: true,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
    },
  },
  {
    path: "/business-plans/:businessPlanId(\\d+)/scenario/create",
    name: "create-scenario",
    component: () =>
      import(
        /* webpackChunkName: "scenarios" */ "@/views/Scenarios/ScenarioCreate.vue"
      ),
    props: true,
    meta: {
      title: "scenario-create",
      header: {
        show: true,
        buttons: {
          visualization: {
            thousands: true,
            compactOrIndirect: false,
            difference: true,
          },
        },
      },
      navbar: { show: true },
      footer: { show: true },
      fullScreen: { show: true },
      store: {
        keepWorkbook: true,
      },
    },
  },

  {
    path: "/info",
    name: "enterprise-info",
    component: () =>
      import(
        /* webpackChunkName: "documents" */ "@/views/enterprises/EnterpriseView.vue"
      ),
    meta: {
      title: "enterprise-info",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },

  {
    path: "/docs/enterprise",
    name: "enterprise-documents",
    component: () =>
      import(/* webpackChunkName: "documents" */ "@/views/DocumentsView.vue"),
    meta: {
      title: "enterprise-documents",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/docs/project",
    name: "project-documents",
    component: () =>
      import(/* webpackChunkName: "documents" */ "@/views/DocumentsView.vue"),
    meta: {
      title: "project-documents",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/docs/other",
    name: "other-documents",
    component: () =>
      import(/* webpackChunkName: "documents" */ "@/views/DocumentsView.vue"),
    meta: {
      title: "other-documents",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/accountant/enterprises",
    name: "accountant-enterprises",
    component: () =>
      import(
        /* webpackChunkName: "accountant-enterprises" */ "@/views/accountant/AccountantEnterprisesView.vue"
      ),
    meta: {
      title: "enterprises-list",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/accountant/enterprises/create",
    name: "accountant-enterprises-create",
    component: () =>
      import(
        /* webpackChunkName: "accountant-enterprises" */ "@/views/enterprises/EnterpriseCreate.vue"
      ),
    meta: {
      title: "enterprise-create",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/dsr",
    name: "dsr",
    component: () =>
      import(/* webpackChunkName: "dsr" */ "@/views/DsrView.vue"),
    props: true,
    meta: {
      title: "dsr.default",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
      container: {
        fillHeight: true,
      },
      fullScreen: { show: true },
    },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/UsersView.vue"),
    props: true,
    meta: {
      title: "users-list",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/accountant/users",
    name: "accountant-users",
    component: () =>
      import(
        /* webpackChunkName: "accountant-users" */ "@/views/accountant/AccountantUsersView.vue"
      ),
    props: true,
    meta: {
      title: "users-list",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/admin/firms",
    name: "admin-firms",
    component: () =>
      import(
        /* webpackChunkName: "admin-firms" */ "@/views/admin/AdminFirmsView.vue"
      ),
    props: true,
    meta: {
      title: "firms-list",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/admin/esg/upload",
    name: "admin-esg-upload",
    component: () =>
      import(
        /* webpackChunkName: "admin-firms" */ "@/views/admin/AdminEsgUploadView.vue"
      ),
    props: true,
    meta: {
      title: "admin-esg-upload",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/admin/firms/:firmId(\\d+)",
    name: "firm",
    component: () =>
      import(/* webpackChunkName: "firm" */ "@/views/admin/AdminFirmView.vue"),
    props: true,
    meta: {
      title: "firm",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/OptionsView.vue"),
    meta: {
      title: "options",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/esg/eligibility-tests",
    name: "eligibility-tests",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "@/views/esg/EligibilityTestsView.vue"
      ),
    meta: {
      title: "esg.default",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/esg/eligibilityTest/:compiledEligibilityTestId(\\d+)/questionnaires/:questionnaireOrder(\\d+)/compilation",
    name: "esg.questionnaires-compilation",
    component: () =>
      import(
        /* webpackChunkName: "esg.questionnaires-compilation" */ "@/views/esg/CompileQuestionnaireView.vue"
      ),
    props: true,
    meta: {
      title: "esg.questionnaires-compilation",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/esg/eligibilityTest/:compiledEligibilityTestId(\\d+)/questionnaires/:questionnaireOrder(\\d+)/result",
    name: "esg-questionnaires-result",
    component: () =>
      import(
        /* webpackChunkName: "esg-questionnaires-result" */ "@/views/esg/ResultQuestionnaireView.vue"
      ),
    props: true,
    meta: {
      title: "esg.eligibility-test-result",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "/esg/eligibilityTest/:compiledEligibilityTestId(\\d+)/documentations",
    name: "esg.documentation",
    component: () =>
      import(
        /* webpackChunkName: "esg-documentations" */ "@/views/esg/CompliantDocumentationView.vue"
      ),
    props: true,
    meta: {
      title: "esg.documentation",
      header: { show: true },
      navbar: { show: true },
      footer: { show: true },
    },
  },
  {
    path: "*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/NotFoundView.vue"),
    meta: {
      title: "Not Found",
      header: { show: false },
      navbar: { show: false },
      footer: { show: false },
      container: {
        fillHeight: true,
      },
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  setI18nLanguage(Store.getters["user/getActiveLocale"]);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.getters["user/userIsLogged"]) {
      next({ name: "login-enterprise" });
    } else {
      const requiredRole = to.meta.requiredRole;
      if (2 >= requiredRole) {
        next();
      } else {
        next({ name: "login-enterprise" });
      }
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Store.dispatch("header/resetState");
  Store.dispatch("businessPlan/resetState");

  if (!to.meta.store || !to.meta.store.keepWorkbook) {
    Store.dispatch("workbook/resetState");
  }
});

export default router;
