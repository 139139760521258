import { axiosClient } from "../axios";
import { getQuery } from "../helper";

const baseUrl = "/api/esg/dsr";

export function getSchema() {
  return axiosClient.get(`${baseUrl}/schema`);
}

/* export function download(options) {
  const query = getQuery(options);
  return axiosClient.get(`${baseUrl}/download`, query);
} */

export function get(id) {
  return axiosClient.get(`${baseUrl}/${id}`);
}

export function getByEnterpriseIdAndYear(enterpriseId, year) {
  const query = getQuery({
    enterpriseId: enterpriseId,
    year: year,
  });
  return axiosClient.get(baseUrl, query);
}

/* export function getBlobByHash(hash) {
  return axiosClient.get(`${baseUrl}/blob/${hash}`);
} */

export function save(data) {
  return axiosClient.post(`${baseUrl}/save`, data);
}

export function remove(id) {
  const query = getQuery({ id });
  return axiosClient.post(`${baseUrl}/delete`, {}, query);
}

/* export function createDsrObject(data) {
  return axiosClient.post(`${baseUrl}/import`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
} */
