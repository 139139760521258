import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import en from "vuetify/lib/locale/en";
import it from "vuetify/lib/locale/it";
import tr from "vuetify/lib/locale/tr";

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#1A67F8",
        secondary: "#000000",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#0c7489",
        success: "#4CAF50",
        warning: "#FFC107",

        iason: "#cd7415",
        lightblue: "#14c6FF",
        yellow: "#FFCF00",
        pink: "#FF1976",
        orange: "#FF8657",
        magenta: "#C33AFC",
        darkblue: "#1E2D56",
        gray: "#909090",
        neutralgray: "#9BA6C1",
        green: "#2ED47A",
        red: "#FF5c4E",
        darkblueshade: "#308DC2",
        lightgray: "#BDBDBD",
        lightpink: "#FFCFE3",
        white: "#FFFFFF",
        gold: "#aa956f",
        blueLight: "#119da4",
        blueMedium: "#0c7489",
        blueDark: "#13505b",
        dark: "#040404",
        light: "#f8f9fa",
        techBlue: "#1A67F8",
        techBlueDark: "#050D21",
        techPurple: "#B46CF8",
        dsrNavigationDrawerCriteria: "#03396c",
        dsrNavigationDrawerA: "#0b76a0",
        dsrNavigationDrawerB: "#006666",
        dsrNavigationDrawer: "#0b76a0",
        dsrActiveListItemCriteria: "#6497b1",
        dsrActiveListItemA: "#0f9ed5",
        dsrActiveListItemB: "#66b2b2",
        dsrActiveListItem: "#0f9ed5",
        dsrNotActiveListItemCriteria: "#011f4b",
        dsrNotActiveListItemA: "#084f6a",
        dsrNotActiveListItemB: "#004c4c",
        dsrNotActiveListItem: "#084f6a",
        dsrFieldTitleCriteria: "#b3cde0",
        dsrFieldTitleA: "#61cbf4",
        dsrFieldTitleB: "#b2d8d8",
        dsrFieldTitle: "#61cbf4",
        dsrProgressBarCriteria: "#0070c0",
        dsrProgressBarA: "#0070c0",
        dsrProgressBarB: "#008080",
        dsrProgressBar: "#0070c0",
        turquoise: "#119da4",
        blueDsrDark: "#113fb2",
      },
    },
  },
  lang: {
    locales: {
      en: en,
      it: it,
      tr: tr,
    },
    current: "it",
  },
});
