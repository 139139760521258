import { DsrApi } from "@/api";
import i18n from "@/i18n";
import router from "@/router";
import { showSuccessSnackbar } from "@/utilities/snackbar";
/* import { JsonUtils } from "@/utilities/jsonUtils"; */

export async function getSchema() {
  const { data } = await DsrApi.getSchema();
  return data;
}

/* export async function getConstraints() {
  const { data } = await DsrApi.getConstraints();
  return data;
}

export async function getAddons() {
  const { data } = await DsrApi.getAddons();
  return data;
}

export async function download(params) {
  let data;
  try {
    ({ data: data } = await DsrApi.download(params));
  } catch (ex) {
    if (ex.response.status === 404) {
      router.push({ name: "balances" });
    }
  }
  return data;
} */

export async function get(id) {
  const { data } = await DsrApi.get(id);
  return data;
}

export async function getByEnterpriseIdAndYear(enterpriseId, year) {
  let data;
  try {
    ({ data: data } = await DsrApi.getByEnterpriseIdAndYear(
      enterpriseId,
      year
    ));
  } catch (ex) {
    if (ex.response.status === 404) {
      router.push({ name: "users" });
    }
  }
  return data;
}

/* export async function getBlobByHash(hash) {
  let { data } = await DsrApi.getBlobByHash(hash);
  data = JSON.parse(data, JsonUtils.infinityReviver);
  return data;
} */

export async function save(dsr) {
  await DsrApi.save(dsr);
  showSuccessSnackbar(200, i18n.t("dsr-saved-correctly"));
}

export async function remove(id) {
  await DsrApi.remove(id);
  showSuccessSnackbar(200, i18n.t("dsr-deleted-correctly"));
}

/* export async function createDsrObject(formData) {
  const { data } = await DsrApi.createDsrObject(formData);
  return data;
} */
